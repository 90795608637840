@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Poppins', 'Helvetica', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #181c32;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.c-line::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 1px;
  background-color: white;
}

.about-us {
  background-image: url('/public/images/about-us/about-us.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%; /* Membuat tinggi body menjadi full height */
}

/* .markdown > * {
  all: revert;
} */

div.jfFaHg svg {
  display: none !important;
}

select {
  text-transform: capitalize;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
